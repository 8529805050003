







































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import ImageUploader from "vue-image-upload-resize";
import formatter from "@/mixins/formatter";
import { AxiosResponse } from "axios";
import { StockMarket } from "@/smartmsi";
import MField from "@/components/MField.vue";
import Field from "@/components/Field.vue";

@Component({
  components: { Field, MField, ImageUploader },
  mixins: [formatter],
})
export default class CompanyForm extends Vue {
  @Prop() companyId!: number;

  company = null;
  legalTypologies = [];
  stockMarkets: StockMarket[] = [];

  contactTemplate = {
    gender: null,
    name: "",
    title: "",
    phone: "",
    email: "",
  };
  countries = [];

  created() {
    // load lists
    this.$api
      .get("legal-typology?per-page=0")
      .then(j => (this.legalTypologies = j.data));
    this.$api.get("country?per-page=0").then(j => (this.countries = j.data));
    this.$api
      .get("stock-market?per-page=0")
      .then(
        (res: AxiosResponse<StockMarket[]>) => (this.stockMarkets = res.data)
      );

    // get or create company object
    if (this.companyId) {
      this.$api
        .get(`company/${this.companyId}?expand=companyActivities`)
        .then(j => {
          this.company = j.data;
        });
    } else {
      this.company = {
        name: "",
        url: "",
        image: false,
        companyActivities: [{}],
        contacts: [{}],
      };
    }
  }

  onSubmit() {
    const call = this.companyId
      ? this.$api.patch(`company/${this.companyId}`, this.company)
      : this.$api.post(`company`, this.company);

    call
      .then(j => {
        this.$router.push(`/company/${j.data.id}`);
      })
      .catch(err => {
        if (err.response) this.$refs.form.setErrors(err.response.data);
        this.$snack.validationError(err.response.data);
      });
  }

  addActivity() {
    this.$set(this.company, "companyActivities", [
      ...(this.company.companyActivities || []),
      {
        activity_id: null,
        percent: null,
      },
    ]);
  }

  addContact() {
    this.company.contacts = [
      ...this.company.contacts,
      { ...this.contactTemplate },
    ];
  }

  removeContact(index: number) {
    this.$delete(this.company.contacts, index);
  }

  setImage(newImage) {
    this.company = { ...this.company, newImage };
  }

  get rules() {
    return this.$store.state.rules;
  }
}
